<template>
    <div>
        <h2>{{text.user_archive_advert_table}}</h2>

        <div class="table-scroller">
            <table class="archive">
                <thead>
                <tr>
                    <th class="w-4/12">{{text.advert_name}}</th>
                    <th class="w-2/12">{{text.business_unit}}</th>
                    <th class="w-2/12">{{text.created_by}}</th>
                    <th class="w-2/12">{{text.date_expiry}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="archiveTableData.length > 0">
                <tr v-for="row in archiveTableData" :key="row.id">
                    <td>
                        <router-link :to="{ name: 'UserAdvertsEdit', params: { 'id' : row.id } }"
                                     :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link>
                    </td>
                    <td>{{row.businessUnit.name}}</td>
                    <td>{{row.ownerName | createdByName(row.isOwner)}}</td>
                    <td>{{row.expiryDate | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'UserAdvertsView'" :routeParams="{ 'id' : row.id }" :linkName="row.name" />
                        <CopyAction :linkName="row.name" @actionCalled="copyAdvert(row.id, 'UserAdvertsEdit')" />
                        <RestoreAction :linkName="text.restore + ' ' + row.name" @actionCalled="restoreAdvert(row.id, 'loadArchiveAdvertsList')" v-if="row.isOwner && row.isRestorable" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="5">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="archiveTablePagination.totalPages > 0"
                    :pagination="archiveTablePagination"
                    @go-previous="loadArchiveAdvertsList"
                    @go-next="loadArchiveAdvertsList"/>
    </div>
</template>

<script>
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {advertActionsMixin} from "@/views/mixins/advertActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";
    import CopyAction from "@/components/actionlinks/copy";
    import RestoreAction from "@/components/actionlinks/restore";

    export default {
        name: 'UserAdvertsArchive',
        components: {Pagination, ViewAction, CopyAction, RestoreAction},
        mixins: [formatContentMixin, advertActionsMixin],
        data() {
            return {
                archiveTableData: [],
                archiveTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        async mounted() {
            await this.loadArchiveAdvertsList();
        }
    }
</script>
