<template>
    <div>
        <h2>{{text.user_draft_advert_table}}</h2>
        <div class="table-scroller">
            <table class="drafts">
                <thead>
                <tr>
                    <th class="w-7/12">{{text.advert_name}}</th>
                    <th class="w-3/12">{{text.date_last_modified}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="draftTableData.length > 0">
                    <tr v-for="row in draftTableData" :key="row.id">
                        <td><router-link :to="{ name: 'UserAdvertsEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                        <td>{{row.currentVersionDate | formatDate}}</td>
                        <td>
                            <EditAction :routeName="'UserAdvertsEdit'" :routeParams="{ 'id' : row.id }" :linkName="row.name" />
                            <CopyAction :linkName="row.name" @actionCalled="copyAdvert(row.id, 'UserAdvertsEdit')" />
                            <DeleteAction :linkName="row.name" @actionCalled="deleteAdvert(row.id, 'loadDraftAdvertsList')" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="3">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="draftTablePagination.totalPages > 0" :pagination="draftTablePagination" @go-previous="loadDraftAdvertsList" @go-next="loadDraftAdvertsList" />

        <h2 class="mt-12">{{text.user_pending_advert_table}}</h2>

        <div class="table-scroller table-shadow">
            <table class="pending">
                <thead>
                <tr>
                    <th class="w-3/12">{{text.advert_name}}</th>
                    <th class="w-3/12">{{text.department}}</th>
                    <th class="w-2/12">{{text.created_by}}</th>
                    <th class="w-1/12">{{text.date_live}}</th>
                    <th class="w-1/12">{{text.date_expiry}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="pendingTableData.length > 0">
                <tr v-for="row in pendingTableData" :key="row.id">
                    <td><router-link :to="{ name: 'UserAdvertsEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td>{{row.businessUnit.name}}</td>
                    <td>{{row.ownerName | createdByName(row.isOwner)}}</td>
                    <td>{{row.liveDate | formatDate}}</td>
                    <td>{{row.expiryDate | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'UserAdvertsView'" :routeParams="{ 'id' : row.id }" :linkName="row.name" />
                        <EditAction :routeName="'UserAdvertsEdit'" :routeParams="{ 'id' : row.id }" :linkName="row.name" v-if="row.isOwner" />
                        <ExportAction :routeName="'UserAdvertsExport'" :routeParams="{ 'id' : row.id }" :linkName="row.name" v-if="row.published" />
                        <CopyAction :linkName="row.name" @actionCalled="copyAdvert(row.id, 'UserAdvertsEdit')" />
                        <ArchiveAction :linkName="row.name" @actionCalled="archiveAdvert(row.id, 'loadPendingAdvertsList')" v-if="row.isOwner" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="6">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="pendingTablePagination.totalPages > 0" :pagination="pendingTablePagination" @go-previous="loadPendingAdvertsList" @go-next="loadPendingAdvertsList" />


        <h2 class="mt-12">{{text.user_active_advert_table}}</h2>
        <div class="table-scroller table-shadow">
            <table class="active">
                <thead>
                <tr>
                    <th class="w-3/12">{{text.advert_name}}</th>
                    <th class="w-3/12">{{text.business_unit}}</th>
                    <th class="w-2/12">{{text.created_by}}</th>
                    <th class="w-1/12">{{text.date_live}}</th>
                    <th class="w-1/12">{{text.date_expiry}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                <tr v-for="row in activeTableData" :key="row.id">
                    <td><router-link :to="{ name: 'UserAdvertsEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td>{{row.businessUnit.name}}</td>
                    <td>{{row.ownerName | createdByName(row.isOwner)}}</td>
                    <td>{{row.liveDate | formatDate}}</td>
                    <td>{{row.expiryDate | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'UserAdvertsView'" :routeParams="{ 'id' : row.id }" :linkName="row.name" />
                        <ExportAction :routeName="'UserAdvertsExport'" :routeParams="{ 'id' : row.id }" :linkName="row.name" v-if="row.published" />
                        <EditAction :routeName="'UserAdvertsEdit'" :routeParams="{ 'id' : row.id }" :linkName="row.name" v-if="row.isOwner" />
                        <CopyAction :linkName="row.name" @actionCalled="copyAdvert(row.id, 'UserAdvertsEdit')" />
                        <ArchiveAction :linkName="row.name" @actionCalled="archiveAdvert(row.id, 'loadPendingAdvertsList')" v-if="row.isOwner" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="6">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="activeTablePagination.totalPages > 0" :pagination="activeTablePagination" @go-previous="loadActiveAdvertsList" @go-next="loadActiveAdvertsList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {advertActionsMixin} from "@/views/mixins/advertActions";

    import Pagination from "@/components/pagination";
    import EditAction from "@/components/actionlinks/edit";
    import ViewAction from "@/components/actionlinks/view";
    import ExportAction from "@/components/actionlinks/export";
    import CopyAction from "@/components/actionlinks/copy";
    import DeleteAction from "@/components/actionlinks/delete";
    import ArchiveAction from "@/components/actionlinks/archive";

    export default {
        name: 'UserAdvertsList',
        components: {Pagination, ViewAction, ExportAction, EditAction, CopyAction, DeleteAction, ArchiveAction},
        mixins: [formatContentMixin,advertActionsMixin],
        data() {
            return {
                activeTableData: [],
                activeTablePagination: [],
                pendingTableData: [],
                pendingTablePagination: [],
                draftTableData: [],
                draftTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        async mounted() {
            await this.loadDraftAdvertsList();
            await this.loadPendingAdvertsList();
            await this.loadActiveAdvertsList();
        }
    }
</script>
